* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Sen !important';
  background-color: #FFFFFF;
}

/*  Input do outlined */
.MuiOutlinedInput-notchedOutline {
  /* border-color: #3A4049 !important; */
}

.Mui-focused {
  color: #3A4049;
}

/* .MuiButtonBase-root {
  width: 100%;
} */

/* .MuiPaper-root {
  background-color: red !important;
} */